import React from "react";
import { Link } from "react-router-dom";
import laplancha from "../media/logos/la-plancha-logo.png";
import hybrid from "../media/logos/hybrid-logo.png";
import flySurf from "../media/logos/fly-surf-logo.png";
import "./VenueCard.scss";

const venues = [
  {
    name: "La Plancha",
    image: laplancha,
    instagramLink:
      "https://www.instagram.com/eatatlaplancha?igsh=MTFmaXZvcnQ3MDhy",
    description:
      "La Plancha offers a live kitchen daily, with professional chefs cooking\
       your favorite dishes to perfection. Our menu celebrates global cuisines,\
        featuring carefully selected favorites. Enjoy fresh salads, juicy burgers,\
         saucy pastas, and hot oven-baked pizzas.",
  },
  {
    name: "Hybrid",
    image: hybrid,
    instagramLink:
      "https://www.instagram.com/hybridelgouna?igsh=emdqaHk4cHBndWRs",
    description:
      "We offer an exclusive outdoor fitness experience like no other.\
     Facing the beach, Hybrid is both a gym and fitness space offering a sea of fun \
     luxuries to celebrate your body & wellbeing. Yoga, group fitness, movement classes\
      and also customized personal training sessions (for ages & levels). ",
  },
  {
    name: "FlySurf Pro",
    image: flySurf,
    instagramLink: "https://www.instagram.com/flysurfpro?igsh=ZjFvaTNhZjRrajU2",
    description:
      "A water adrenaline rush or smooth fun are both available at our eFoil center;\
     FlySurfPro. Introducing Fliteboard™ eFoil for the first time at El Gouna! Find freedom and \
     fly over water on the world's most advanced electric foil board.rofessional instructions are\
      provided (mandatory) for your safety",
  },
];

const VenueCard = () => {
  return (
    <div className="venue-container">
      {venues.map((venue) => (
        <div className="venue" key={venue.name}>
          <div className="image-container">
            <Link
              to={venue.instagramLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={venue.image} alt={venue.name} loading="lazy"></img>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VenueCard;
