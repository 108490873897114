import React from "react";
import VenueCard from "./VenueCard";
import Activities from "./Activities";
import "./Home.scss";

import logoColor from "../media/logos/logo-color.png";
import BahrPopup from "./BahrPopup";
import vid2 from "../HeroVid.webm";
import NavBar from "../Nav_Footer/NavBar";
import Footer from "../Nav_Footer/Footer";
import { Link } from "react-router-dom";



const HomePage = () => {
  return (
    <>
      <NavBar />
      <BahrPopup></BahrPopup>
      <div className="homepage">
        {/* Video Section */}
        <div className="video-container">
          <video src={vid2} autoPlay muted loop className="intro-video" />

          <div className="cta-overlay">
            <div className="welcome-text">
              <h2>BOOK YOUR BOAT!</h2>
              <div className="links">
                <Link className="book-button" to="/booking">
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="welcome">
          <img src={logoColor}></img>
          <div className="welcome-text">
            <h2>Welcome to</h2>
            <h2>El Gouna Sailing Club</h2>

            <p>
              Enjoy being our guest to experience a beach day like no other,
              endless water activities & sports, sailing classes for all ages,
              or book your private boat and party at sea.
            </p>
            <div className="links">
              <Link
                className="ig"
                to="https://www.instagram.com/elgounasailingclub?igsh=MWNyYThpbzl0d3VhZQ%3D%3D&utm_source=qr"
                target="_blank"
              >
                <i className="fa-brands fa-instagram"></i>
              </Link>
              <Link
                className="fb"
                to="https://www.facebook.com/share/5rWkrPpanencuja8/?mibextid=LQQJ4d"
                target="_blank"
              >
                <i className="fa-brands fa-facebook"></i>
              </Link>
              <Link
                to="https://maps.app.goo.gl/VqCX6rMyVbf71xC28?g_st=com.google.maps.preview.copy"
                target="_blank"
              >
                <i class="fa-solid fa-location-dot"></i>
              </Link>
            </div>
          </div>
        </div>
        <section className="instagram-follow">
          <div className="instagram-text">
            <h2>FOLLOW US ON INSTAGRAM!</h2>
            <p>TO KNOW WHAT’S HAPPENING</p>
            <a
              href="https://www.instagram.com/elgounasailingclub?igsh=MWNyYThpbzl0d3VhZQ%3D%3D&utm_source=qr" // Replace with your Instagram URL
              target="_blank"
              rel="noopener noreferrer"
              className="instagram-button"
            >
              Visit Our Instagram
            </a>
          </div>
        </section>
        <section className="tour">
          <div className="tour-text">
            <h2>TAKE A VIRTUAL TOUR!</h2>
            <p>EXPLORE NOW</p>
            <a
              href="https://idvdigital.com/360-vr/elgouna-sailing/"
              target="_blank"
              rel="noopener noreferrer"
              className="tour-button"
            >
              Start Tour
            </a>
          </div>
        </section>
        <div className="book-now">
          <div className="welcome-text">
            <h2>DON'T MISS THE BOAT!</h2>
            <p>
              ENJOY AN AMAZING DAY AT SEA
            </p>
            <div className="links">
              <Link className="book-button" to="/booking">
                Book Now
              </Link>
            </div>
          </div>
        </div>

        <div className="intro">
          <h2>ACTIVITIES</h2>
        </div>

        <Activities />
        <div className="intro">
          <h2>VENUES</h2>
        </div>
        <VenueCard/>
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
