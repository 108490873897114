import React from "react";
import "./Activities.scss";
// import catamaran from "../media/images/activities/Catamaran.jpg";
// import bahia from "../media/images/activities/Bahia.jpg";
// import boatExcursion from "../media/images/activities/BoatExcursions.jpg";
// import coastalRowing from "../media/images/activities/CoastalRowing.jpg";
// import eFoil from "../media/images/activities/E-Foiling.jpg";
// import fitnessHub from "../media/images/activities/FitnessHub.jpg";
// import kayaking from "../media/images/activities/Kayaking.jpg";
// import lazer from "../media/images/activities/Lazer.jpg";
// import optimist from "../media/images/activities/Optimist.jpg";
// import pedalo from "../media/images/activities/Pedalo.jpg";
// import seaCruiser from "../media/images/activities/SeaCruiser.jpg";
// import SUP from "../media/images/activities/SUP.jpg";
// import taxiBoat from "../media/images/activities2/TaxiBoat.jpg";

const activities = [
  {
    name: "Sailing",
    description:
      "The most popular sailboat in the world. It is traditionally used as a trainer for sailors under 14 years of age who weigh less than 110 pounds. It is a single-handed boat with a rig that is 3.3 square meters.",
    price: 'kids course 5h for 150£',
    image: "Optimist",
  },
  {
    name: "ROWING",
    description:
      "Uses wider hulls with a levelled off stern to allow water to flow out of the boat. It usually takes place on open water and does not shy away from waves and wind (unlike its flat-water counterpart).",
    price: '400 EGP/session',
    image: "rowing",
  },
  {
    name: "KAYAKING",
    description:
      "Kayaking is a watersport that involves sitting in a small watercraft, propelling yourself through the water with a double-sided paddle.",
    price: 'Single 300 EGP/h - Double 400 EGP/h',
    image: "kayaking",
  },
  {
    name: "SEA CRUISER",
    description:
      "A Multihull Electric Watercraft that is suitable for all ages.",
    price: '800 EGP/h',
    image: "seacruiser",
  },
  {
    name: "PEDALO",
    description:
      "A pedalo or paddle boat is a small human-powered watercraft propelled by the action of pedals turning a paddle wheel.",
    price: '500 EGP/h',
    image: "pedalo",
  },
  {
    name: "Stand-Up Paddleboard",
    description:
      "SUP stands for stand-up paddleboarding. It's like a cross between canoeing and surfing. SUPs can be used on the sea for surfing or touring, or on inland waterways such as canals, rivers, or lakes. You can even do yoga on them.",
    price: '500 EGP/h',
    image: "SUP",
  },
  {
    name: "E-FOILING",
    description:
      "Known as the eFoil, short for electric-powered hydrofoil surfboard, this Back-to-the-Future-looking invention glides silently across the water at speeds up to 25 mph as you balance on top. You won't need a boat.",
    price: 'e Scooter 70€/30 min - Board 150€/50 min',
    image: "efoil2",
  },
  {
    name: "Outdoor Fitness",
    image: "outdoor",
  },
];

function Activities() {
  return (
    <div className="activity-container">
      {activities.map((activity) => (
        <div className="activity" key={activity.name}>
          <div className="image-container">
            <img
              src={`https://${process.env.REACT_APP_BACKEND}/images/ActivitiesWebpComp/${activity.image}.webp`}
              alt={activity.name}
              loading="lazy"
            ></img>
          </div>
          <h2>{activity.name.toLocaleUpperCase()}</h2>
          {/* <p>{activity.description}</p>
          <p>
            <strong>{activity.price}</strong>
          </p> */}
        </div>
      ))}
    </div>
  );
}

export default Activities;
